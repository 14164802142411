
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {ROUTE_COMPANY, ROUTE_HOME, ROUTE_LOGIN} from "@/router/routes";
import AuthRepository from "@/api/repositories/AuthRepository";
import {AUTH_STORE_NAME, AuthStoreActions} from "@/store/auth.store";
import Restauranteur from "@/models/Restaurateur";
import {namespace} from "vuex-class";
import {AccountType} from "@/enum/AccountType.enum";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
      /* webpackChunkName: "LogoComponent" */
      '@/components/Logo.component.vue'
    ),
  },
})
export default class ContainersView extends Vue {
  @AuthStore.Action(AuthStoreActions.LOGIN_WITH_TOKEN)
  private loginWithTokenAction!: (payload: { token: string, refreshToken: string }) => Promise< Restauranteur | null>;

  /**
   * Reset Token fetched by the URL Query
   * @private
   */
  private token?: string;

  /**
   * Loading State Bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Alert Message to Show
   * @private
   */
  private alertMessage: string = "";

  /**
   * flag that indicates if the success message should be shown
   * @private
   */
  private showSuccessMessage = false;

  /**
   * flag that indicates if the user is app user
   * @private
   */
  private isAppUser = false;

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  private user: Restauranteur | null = null;

  private async created(): Promise<void> {
    // try to get token from query param
    const token = this.$route.query.token;

    // Token is not available through a query param
    // show an error describing that issue
    if (!token) {
      this.alertMessage = this.$t("CONFIRM_MAIL.INVALID_LINK").toString();
      this.showAlert = true;
      return;
    }

    // Parse given token as String
    this.token = token as string;

    // tries to confirm the mail
    await this.confirmMail();
  }

  /**
   * is called when the user enters the view, tries to confirm the mail of the user by the token of the link
   */
  private async confirmMail() {
    // Try to set the new Password
    try {
      // set Loading to be active
      this.isLoading = true;

      // tries to verify the new email and gets the token from the response
      const verificationResponse = await AuthRepository.verifyEmailWithToken(this.token!);
      const token = verificationResponse.data['access_token'];
      const refreshToken = verificationResponse.data['refresh_token'];

      if(!token || !refreshToken) {
        this.isAppUser = true;
        this.showSuccessMessage = true;
        this.isLoading = false;
        return;
      }

      // logs in the user, checks if the user has the correct role
      this.user = await this.loginWithTokenAction({token, refreshToken});
      if(!this.user) {
        this.isAppUser = true;
      }

      this.showSuccessMessage = true;
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
            // Token Expired
          case 403:
            this.alertMessage = this.$t("PASSWORD.INVALID_RESET_TOKEN").toString();
            break;
            // No Account or Token Found
          case 404:
            this.alertMessage = this.$t("PASSWORD.ACCOUNT_OR_TOKEN_NOT_FOUND").toString();
            break;
          default:
            this.alertMessage = this.$t("CONFIRM_MAIL.CONFIRM_FAILED").toString();
        }

        // Show Alert Message
        this.showAlert = true;
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the user taps on the confirm button, is redirected to home view which will validate if the user is
   * authenticated etc.
   * @private
   */
  private async onContinuePressed(): Promise<any> {
    if(this.isAppUser) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = process.env.VUE_APP_BASE_URL + '/app';
      a.click();
      document.body.removeChild(a);
    }

    // checks if user is null and returns
    if(!this.user?.account) {
      return await this.$router.push({ name: ROUTE_LOGIN });
    }

    // if user is pb, go to company overview
    if(this.user!.account!.role === AccountType.PB) {
      // pushes the application to the home view
      return await this.$router.push({ name: ROUTE_HOME });
    }

    // if user is gn, go to specific company view with the id of the company of the user
    if(this.user!.account!.role === AccountType.GN) {
      // pushes the application to the home view
      return await this.$router.push({ name: ROUTE_COMPANY, params: {id: this.user!.company!.id!}});
    }
  }

  /**
   * Is Mobile Vuetify Breakpoint Triggered
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }
}
